.profile-container {
    width: 100%;

    .card-title {
        padding: 2px;
        border-radius: 8px;
        margin: 10px;
        cursor: pointer;
        border: 1px solid;

        .card-header-profile {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 10px;
            align-items: center;
        }

        .card-header-text-profile {
            margin-top: 20px;
            font-size:2em;
            margin: 10px;
        }
    }

    .profile-card {
        padding: 2px;
        margin: 10px;
        border-bottom: 1px solid;

        .info-header-profile {
            display: flex;
            font-size: 2em;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .card-section {
            padding: 10px;

            .info-content {
                display: flex;
                flex-direction: column;
                padding: 5px;

                .info-field-col {
                    display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin: 5px;

                    .slack-email-input {
                        border: 1px solid;
                        border-radius: 4px;
                        height: 3em;
                        margin-bottom: 10px;
                        padding: 5px;
                        width: 100%;
                    }

                    .connect-slack-button {
                        width: 100%;
                    }
                }

                .info-field {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .profile-icon-container {
                        border-radius: 50%;
                        height: 25px;
                        padding: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .field {
                        font-size: 1.3em;
                        padding: 5px;
                        margin: 5px;
                    }
                }

                .change-password-button-container {
                    padding: 10px;
                 
                    .change-password-button {
                        width: 100%;
                    }
                 }
            }
        }
    }
}

.logout-button-container {
    padding: 35px;
    display: flex;

    .update-profile-button {
        margin-bottom: 10px !important;
        width: 100%;
    }
 }


@media only screen and (min-width: 650px) {
	.content {
        display: flex;
        justify-content: center;
	}
}