.week-header-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
}

.weeks-container {
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.week-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	width: 100%;
}

.week-header-next {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.week-arrow-next-icon {
	transform: scale(1);
}

.weeks-button-style {
	width: 100%;
	margin: 5px !important;
}

.next-button-style {
	margin: 5px !important;
}

.week-arrow-icon {
	transform: scale(1);
}

.week-title {
	font-size:1.5em;
}

.week-filter-icon {
	position: absolute;
    top: 55px;
    right: 20px;
    opacity: 0;
	height: 60px;
}

.week-filter-notif {
	position: absolute;
    top: 50px;
    right: 10px;
	border-radius: 50%;
	height: 20px;
	width: 20px;
}
