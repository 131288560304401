.list-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 10px;
    width: 100%;
}

.user-pick-count {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    border: 3px solid;
    margin-left: 48px;
    margin-top: -23px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.empty-streak {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    margin: 10px;
}