.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header {
        padding: 10px;

        .title {
            font-size: 35px;
            font-weight: bold;
            width: auto;
            display: flex;
            align-items: flex-start;
            line-height: 1em;
            padding-bottom: 10px;
        }

    }
}

.dashboard-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;

    .sub-title {
        font-size: 25px;
        font-weight: bold;
        padding: 10px 0px 10px 0px;
        display: flex;

        .count {
            margin-left: 10px;
        }
    }

    .streak-standings {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .user-stats {
        width: 100%;
    }
}

.indented-title {
    margin-left: 15px;
}

