.no-games-set {
	font-size: 2em;
	display: flex;
	justify-content: center;
	align-content: center;
	text-align: center;
	height: 250px;
	margin-top:20%;
	padding: 10px;
	opacity: .4;
}

.small-height {
	height: 64px;
}

.large-height {
	height: 500px;
}

.games-container {
	display: flex;
	flex-direction: column;
}

.game-container {
	padding-top: 5px;
	padding-bottom: 5px;
}

.full-row {
	width: 100%;
}

.separator {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid;
	margin: 10px;
}

.submit-container {
	position: fixed;
	right: 0px;
	width: 100%;
	height: 65px;
	transition-duration: 500ms;
	transition-timing-function: ease-in-out;
	display:flex;
	z-index: 3;
}

.hide-submit-button {
	bottom: -65px;
}

.show-submit-button {
	bottom: 100px;
}

.submit-button {
	width: 100%;
	margin: 10px !important;
}

@media only screen and (min-width: 650px) {
	.games-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
	}

	.show-submit-button {
		bottom: 20px;
	}
	
	.submit-button {
		width: 500px;
	}

	.submit-container {
		justify-content: center;
	}
}