.team-group {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.game-card {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	margin-bottom: 15px;
	margin-right: 10px;
	margin-left: 10px;
}

.date-text {
	font-size: 1em;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.tiny-text-middle{
	font-size:.75em; 
	margin:0; 
	text-align:center;
}

.medium-text-middle {
	font-size:1.5em; 
	margin:0; 
	text-align:center;
}

.medium-text{
	font-size:2em; 
	margin:0; 
}

.status-icon {
	transform: scale(1.5);
	margin-top: 35px;
	margin-right: 2%;
	margin-left: 2%;
}

.remove {
	opacity: .3;
	transition-duration: 500ms;
	transition-timing-function: ease-in-out;
	pointer-events: none;
}

@media only screen and (min-width: 650px) {
	.game-card  {
		margin: 10px;
	}
}
