.xsmall {
    width: 40px;
    height: 40px;
}

.small {
    width: 50px;
    height: 50px;
}

.medium {
    width: 100px;
    height: 100px;
}

.large {
    width: 200px;
    height: 200px;
}