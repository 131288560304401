.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.image-icon {
    display: flex;
	justify-content: center;
	align-items: center;
    border-radius: 50%;
}

.x-small-image {
    height: 25px;
    width: 25px;
    border: solid 1px;
    font-size: .7em;
}

.small-image {
    height: 35px;
    width: 35px;
    border: solid 1px;
    font-size: .8em;
}

.medium-image {
    height: 45px;
    width: 45px;
    border: solid 2px;
    font-size: 1.5em;
}

.large-image {
    height: 70px;
    width: 70px;
    border: solid 4px;
	font-size: 2em;
}