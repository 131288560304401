.home-content {
    width: 100%;
    padding-bottom: 140px !important;
    display:flex;
    justify-content: center;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.home-main {
    width: 100%;
    margin: 0px !important;
}

@media only screen and (min-width: 650px) {
    .home-content {
        padding-bottom: 10px;
        padding-top: 65px !important;
    }

    .home-side-content {
        padding-bottom: 10px;
        padding-top: 125px !important;
    }
}