.loginContainer {
	display: flex;
	flex-direction: column;
	position: absolute;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.loginHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
}

.submitLoader {
	display: flex;
	justify-content: center;
	align-items: center;
    padding: 10px;
}

.icon {
    width: 100px;
}

.loginInput {
    width: 265px;
}

.loginForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.paddingBottom {
    padding-bottom:20px;
}

.loginTitle {
    padding: 10px;
    font-size: 2em;
    margin-bottom:10px;
}

.pointer {
    cursor: pointer;
}

.link {
    color: blue;
    text-decoration: underline;
}

.forgot-password-link {
    display: flex;
    flex-direction: row;
    padding: 10px;
}