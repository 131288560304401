.team-group {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.game-card {
	display: flex;
	flex-direction: column;
	margin: 10px;
}

.container {
	width:100%;
}