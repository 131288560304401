.other-picks-container {
	display: flex;
	flex-direction: column;
	padding: 10px;
	overflow-y: auto;
	height: 100%;
	touch-action: pan-y !important;
}

.standing-card {
	display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 0px !important;
    margin: 4px !important;

	&:hover {
		border: 1px solid;
	}

	&-bonus-icon {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		transform: scale(1.3);
		padding-right: 10px;
	}
}

.rank {
	width: 80px;
	display: flex;
	justify-content: center;
	border-right: solid 1px;
	margin: 5px;

	&-status-icon {
		transform: scale(2);
	}

	&-content {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 15px;
	}

	&-font {
		font-size: 1.5em;
	}
}

.user {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 35px;
	padding-top: 5px;

	&-content {
		font-size: .6em;
		text-overflow: ellipsis;
	}
}

.bonus {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
}

.win-pct {
	display: flex;
	width: 70px;
	justify-content: center;
	font-size: 1.5em;
}

.stacked-stats {
	display: flex;
	flex-direction: column;
	align-items: center;

	&-font {
		font-size: small;
	}
}

.selected {
	opacity: 10%;
}

.disable {
	pointer-events: none;
}

.pick-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	margin: 10px;
	cursor: pointer;
	border: 1px solid;
}

.pick-title {
	font-size:1.5em;
    margin: 15px;
}

.row-arrow-icon {
	transform: scale(1);
}

.leader-icon {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	transform: scale(1.5);
}