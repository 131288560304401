.schedule-container {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    flex-direction: column;
}

.schedule {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 100%;
}

.schedule-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.schedule-card {
    display: flex;
    justify-content: space-between;
    border-left: solid 4px;
    margin: 5px;
    padding: 5px;
    width: 100%;
}

.schedule-topic {
    font-weight: bold;
    font-size: large;
}

.schedule-subtopic {
    font-size: 15px;
}

.sub-title {
    font-size: 25px;
    font-weight: bold;
}

.empty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    margin: 10px;
}