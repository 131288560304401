.theme-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5px;
    justify-content: center;
}

.theme-button {
    margin: 5px !important;
    width: 95px;
    height: 45px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}