.date-title{
    display: flex;
    flex-direction: row;
    justify-content: start-end;
    font-size: .8em;
    padding: 5px;
    border-bottom: solid 1px;
}

.announcement-date-text {
    font-size: 1.4em;
}

.announcement-card {
    padding: 2px;
    margin: 10px;
}

.announcement-content {
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 15px;
}

.announcement-container {
    padding: 10px;
}

.no-messages-set {
	font-size: 2em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	text-align: center;
	height: 250px;
	margin-top:20%;
	padding: 10px;
}

.no-messages-content {
    opacity: .4;
    padding: 10px;
}

@media only screen and (min-width: 650px) {
	.content {
        display: flex;
        justify-content: center;
	}
}