.picks-data-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

.picks-data-container-final {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 10px;
	padding-right: 10px;
}

.picks-data-group {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.picks-data-group-final {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.pick-data {
	display: flex;
	justify-content: center;
	align-items: center;
	transform: scale(1.1);
}

.pick-data-icon {
	padding: 5px;
}

.picks-data-overview {
	width: 100%;
	padding: 0px !important;
}

.pick-data-button-group {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.show-less-button-group {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.picks-data-users {
	display: flex;
	justify-content: space-between;
	flex-direction: row
}

.users-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.user-item {
	margin: 5px !important;
}

.users-pick-button-content {
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.show-less-button {
	padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
}

.floating-users-pick-button {
	float: left;
	border-radius: 1rem;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 150px;
    padding-left: 15px;
	padding-top: 5px;
}

.users-pick-list {
	margin-top: 5px;
	margin-right: 5px;
	margin-left: 5px;
}

.user-pick-label-container {
	display: flex;
	justify-content: flex-start;
}

.user-pick-label {
	display: flex !important;
	flex-direction: row;
	width: auto;
	background-color: transparent !important;
	flex-wrap: wrap;
}

.user-pick-font {
	margin-right: 5px;
	display: flex;
}

.user-data-images {
	margin-left:-8px;
}

@media only screen and (min-width: 650px) {
	.picks-data-container {
		width: auto;
		right: auto;
		padding: 5px;
		padding-top: 10px;
	}

	.picks-data-container-final {
		width: auto;
		right: auto;
		padding-left: 0px;
		padding-right: 0px;
	}
}