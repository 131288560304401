.mini-pick-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.pick-mini-status-icon {
    margin: 0px !important;
    font-size: 100%;
}

.pick-mini-icon {
    text-align: center;
	border-radius: 50%;
    border: solid 2px;
    width: 25px;
}

.pick-mini-score-time {
    display: flex;
    align-items: center;
    font-size: 14px;

    .pick-mini-score-icon {
        transform: scale(2);
    }
}

.pick-mini-card {
    border: solid 2px;
    border-radius: 8px;
}

.pick-mini-card {
    display: flex;
    flex-direction: column;
    width: 150px;
    padding: 5px;
    margin: 10px;
    justify-content: space-between;
}

.pick-mini-spread {
    font-size: 20px;
    font-weight: bolder;
}

.pick-mini-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.pick-mini-team {
    font-size: 15px;
    display: flex;
    padding: 5px;
}

.pick-mini-score {
    display: flex;
    justify-content: space-between;;
    padding: 5px;
}

.empty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    margin: 10px;
}