.nav-container {
    position: fixed;
    height: 80px;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    padding-bottom: 30px;
}

.button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-button {
    box-shadow: none !important;
}

.logo-container {
    padding: 11px;
}

.logo-container-large {
    display: none;
}

.nav-icon {
    z-index: 2;
}

.circle-notify-container {
    position: absolute;
    z-index: 1;
    padding-left: 5%;
    margin-top: -15px;
}

.circle-notify {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 0;
}

.pulse {
    animation: heart-pulse 8s;
}

.main-circle {
    border-radius: 50%;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 60px;
    top: 10;
    margin-top: -40px;
    border: 2px solid;
}


@keyframes heart-pulse {
    0% {
      transform: scale(1); /* scaling to 0 */
      opacity: .5;
    }
    15% {
        transform: scale(6); /* increasing the size */
        opacity: 0;
    }
    25% {
      transform: scale(1); /* increasing the size */
      opacity: 0;
    }
    26% {
        transform: scale(1); /* increasing the size */
        opacity: .5;
      }
    35% {
        transform: scale(6); /* increasing the size */
        opacity: 0;
      }
    45% {
      transform: scale(1); /* decreasing the size */
      opacity: 0;
    }
    46% {
        transform: scale(1); /* decreasing the size */
        opacity: .5;
      }
    55% {
        transform: scale(6); /* increasing the size */
        opacity: 0;
      }
    65% {
        transform: scale(1); /* decreasing the size */
        opacity: 0;
    }
    66% {
        transform: scale(1); /* decreasing the size */
        opacity: .5;
    }
    75% {
        transform: scale(6); /* decreasing the size */
        opacity: 0;
    }
    85% {
        transform: scale(1); /* decreasing the size */
        opacity: 0;
    }
    86% {
        transform: scale(1); /* decreasing the size */
        opacity: .5;
    }
    100% {
        transform: scale(6); /* decreasing the size */
        opacity: 0;
    }
  }

@media only screen and (min-width: 650px) {
	.nav-container {
        top: 0;
        bottom: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
	}

    .logo-container {
        display: none;
    }

    .logo-container-large {
        display: flex;
    }

    .circle-notify-container {
        padding-left: 30px;
    }

    .main-circle-container {
        display: none !important;
    }
}

.main {
    font-size: 1.2em;
    margin: 5px;
}

.subtitle {
    font-size: 1em;
}

.sidenav-button {
	transform: scale(1.5);
}
.notif-icon {
    border-radius: 50% !important;
    right: auto !important;
}
.edit-button {
    border:  1px solid;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 64px;
}

.title-group {
    display: flex;
    align-items: center;
}

.notification-icon {
    position: absolute;
    left: 40px;
    top: 10px;
    height: 17px;
    width: 17px;
    border-radius: 16px;
}

.edit-loader {
	display: flex;
	justify-content: center;
	align-items: center;
}